<template>
  <div>
    <fieldsRows
      v-if="templateContent != null"
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="{...value,ip:valueLine.target.ip}"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  components: { fieldsRows },
  data() {
    return {
      localIpv: [["ipv4", "IPv4"],["ipv6","IPv6"],["ipv4&v6","ipv4&v6"]],
    };
  },
  props: ["field", "fieldAttributes", "result", "value","valueLine"],

  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: "line",
            fields: {

              ip: {
                type: "select",
                associatedOption: this.localIpv,
                name: "IP Version",
                fieldAttrInput: { class: "required" },
                colAttrs: { cols: 1 },
              },
              // target: {
              //   type: "text",
              //   name: "Target",
              //   // fieldAttrInput: {
              //   //   class: "required IP Domain",
              //   // },
              //   inputFieldFormatter: "ip4ip6ValidatedInput",
              //   colAttrs: { style: "max-width:378px", class: "" },
              // },
              count: {
                type: "number",
                name: "Count",
                colAttrs: { cols: 1 },
              },
              ttl: {
                type: "number",
                name: "TTL",
                colAttrs: { cols: 1 },
              },
              packet: {
                type: "number",
                name: "Packet Size",
                colAttrs: { cols: 1 },
              },
            },
          },
        },

        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };

      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = { ...this.value };
      if( index!="ip"){
        this.$set(localValue, index, value);
        this.$emit("input", localValue);
      }else if( index=="ip"){
        console.log("her")
        // this.$set(this.valueLine.target, index, value);
        console.log(this.valueLine)
        this.$emit("ip-changed",value);
      }
    },
  },
};
</script>